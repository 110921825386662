.add-item {
    border-radius: 2px;
    border: 1px solid #dee2e6;
    margin-right: 50px;
    background-color: rgb(237, 237, 237);
    padding: 0 15px 40px 25px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
    margin-bottom: 34px;  
    margin-right: auto;
  }
  .display-item {
    width: 65%;
    margin-left: 50px;
  }

  .container-box {
    display: flex;
    max-width: auto !important;
    margin: 10px 50px 10px 50px;
    padding-top: 30px;
  }

  .login {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px !important;
    margin-top: 20px;
  }

  .bg-massey {
     background-color: rgb(0, 75, 141) !important;
  }

  .btn-light-massey {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #000000 !important;
  }

  .btn-dark-massey {
    color: #ffffff !important;
    background-color: rgb(0, 75, 141) !important;
    border-color: #000000 !important;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: rgb(0, 75, 141) !important;
    border-color: rgb(0, 75, 141) !important;
  }

  .b-massey{
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2);
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1 !important;
    align-items: center;
  }